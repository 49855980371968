import React from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from './LandingPage';
import { BrowserRouter } from 'react-router-dom';


const App = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
  return (
    <BrowserRouter>
      <LandingPage />
    </BrowserRouter>
  )
}

export default App
import React from 'react'
import NavbarL from './component/nav/NavbarL'
import FooterL from './component/footer/FooterL'
import BanneL from './component/banner/BanneL'
import MagicClub from './component/club/MagicClub'
import MobileApp from './component/mobileapp/MobileApp'
import WhitePaper from './component/whitepper/WhitePaper'
import Scrollbtn from './component/scroll/Scrollbtn'
import FAQ from './component/faq/FAQ'

const LandingPage = () => {


  return (
    <div>
      <NavbarL />
      <BanneL />
      <div className="site-section" id="magicshoesmarketplace">
        <MagicClub />
        <MobileApp />
        <WhitePaper />
        <FAQ />
        <Scrollbtn />
        <FooterL />
      </div>
    </div>
  )
}

export default LandingPage
import React, { useState, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios';
import CountUp from 'react-countup';

const MagicClub = () => {
  // const [totaldata, settotaldata] = useState();
  const [finalmcp, setFinalMcp] = useState(0);
  const [finalkm, setFinalKm] = useState(0);
  const [updateMcp, setUpdateMcp] = useState(0);
  const [updateKm, setUpdateKm] = useState(0);

  useEffect(() => {
    async function fetchData($flag = 0) {
      try {
        const res = await axios.get(
          'https://magic-back-mainnet.vercel.app/api/magicshoes/totalstatstics'
        );
        // settotaldata(res.data.body.data);
        if ($flag == 0) {
          setFinalMcp(res.data.body.data?.mcpTotalMining)
          setFinalKm(res.data.body.data?.totalDistance)
          setUpdateMcp(res.data.body.data?.mcpTotalMining)
          setUpdateKm(res.data.body.data?.totalDistance)
        } else {         
          setUpdateMcp(res.data.body.data?.mcpTotalMining)
          setUpdateKm(res.data.body.data?.totalDistance)
        }

      } catch (err) {
        console.log(err);
      }
    }
    fetchData(0);
    setInterval(function () {
      //Delay a bit before calling the function again.
      // incNbrRec(i + 1, endNbr, elt);
      fetchData(1);
    }, 5000);
  }, []);

  const gen = [
    {
      imgs: require('../../assert/images/img_gara.png'),
      title: 'G.ARA',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 0.98 MCP',
    },
    {
      imgs: require('../../assert/images/img_gdawon.png'),
      title: 'G. DAWON',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 1.96 MCP',
    },
    {
      imgs: require('../../assert/images/img_graon.png'),
      title: 'G. RAON',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 2.94 MCP',
    },
    {
      imgs: require('../../assert/images/img_gmaru.png'),
      title: 'G. MARU',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 3.92 MCP',
    },
  ];
  const rare = [
    {
      id: 1,
      imgs: require('../../assert/images/img-R-ARA.png'),
      title: 'R.ARA',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 1.2 MCP',
    },
    {
      id: 2,
      imgs: require('../../assert/images/img-R-Dawon.png'),
      title: 'R. DAWON',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 2.4 MCP',
    },
    {
      id: 3,
      imgs: require('../../assert/images/img-R-RAON.png'),
      title: 'R. RAON',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 3.6 MCP',
    },
    {
      id: 4,
      imgs: require('../../assert/images/img-R-maru.png'),
      title: 'R. MARU',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 4.8 MCP',
    },
  ];
  const epic = [
    {
      id: 5,
      imgs: require('../../assert/images/img-epic1.png'),
      title: 'E.ARA',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 3.8 MCP',
    },
    {
      id: 6,
      imgs: require('../../assert/images/img-epic2.png'),
      title: 'E. DAWON',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 5.2 MCP',
    },
    {
      id: 7,
      imgs: require('../../assert/images/img-epic3.png'),
      title: 'E. RAON',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 10.2 MCP',
    },
    {
      id: 8,
      imgs: require('../../assert/images/img-epic4.png'),
      title: 'E. MARU',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 22.2 MCP',
    },
  ];
  const la = [
    {
      id: 9,
      imgs: require('../../assert/images/img_l1.png'),
      title: 'L.ARA',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 10.4 MCP',
    },
    {
      id: 10,
      imgs: require('../../assert/images/img_l2.png'),
      title: 'L. DAWON',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 22.8 MCP',
    },
    {
      id: 11,
      imgs: require('../../assert/images/img_l3.png'),
      title: 'L. RAON',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 46.6 MCP',
    },
    {
      id: 12,
      imgs: require('../../assert/images/img_l4.png'),
      title: 'L. MARU',
      speed: '1~30 km/h',
      level: '1 level',
      time: '1min = 94 MCP',
    },
  ];
  return (
    <>
      <ScrollAnimation animateIn="flipInY" animateOut="flipOutY" duration={2}>
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col-md-7 text-center">
              <img
                src={require('../../assert/images/img-deviceview.png')}
                className="img-fluid border_radius_10"
                alt="img"
                title="MagicShoes"
              />
            </div>
          </div>
        </div>
      </ScrollAnimation>
      <div className="container">
        <div className="row mb-5 justify-content-center">
          <div className="col-md-7 text-center">
            <img
              src={require('../../assert/images/loading.gif')}
              className="img-fluid border_radius_10"
              alt="img"
              title="MagicShoes"
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mb-5 justify-content-center">
          <div className="col-md-12 text-center">
            <div className="block-heading-1 text-white">
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <p className="txt-bold-64 txt_mob">MagicShoes NFT</p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceInLeft"
                animateOut="bounceOutRight"
              >
                <p>
                  Magic Shoes is an M2E fitness reward platform that can be
                  played while walking, running, or riding a bicycle. Download
                  the app and equip Magic Shoes NFT sneakers to earn tokens.
                  Each sneaker has a unique design and rarity, and can be
                  upgraded to make it even more powerful.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <ScrollAnimation animateIn="flipInY" animateOut="flipOutY">
          <div className="row mb-5 justify-content-center">
            <div className="col-md-12  bg_shoes ">
              <div className="centered content_ca">
                <div className="txt_caption ">
                  <h2 className="text-white">MCP TOTAL MINING</h2>
                  <p className="text-white txt-bold-64">
                    {/* {updateMcp.toLocaleString()}{' '} */}
                    <CountUp start={finalmcp} end={updateMcp} 
                    duration={50} 
                    // separator=" "
                    decimals={3}
                    decimal="."
                    // suffix=" MCP" 
                    />
                    <sub style={{ fontSize: '24px' }}>MCP</sub>
                  </p>
                  <div className="border_bottom"></div>
                </div>
                <hr className="txt_end" />
                <div className="txt_caption ">
                  <h2 className="text-white">TOTAL DISTANCE(KM)</h2>
                  <p className="text-white txt-bold-64">
                    {/* {updateKm.toLocaleString()}{' '} */}
                    <CountUp start={finalkm} end={updateKm} 
                    duration={500} 
                    // separator=" "
                    decimals={3}
                    decimal="."
                    // suffix=" MCP" 
                    />
                    <sub style={{ fontSize: '24px' }}>Km</sub>
                  </p>
                  <div className="border_bottom"></div>
                </div>
                <hr className="txt_end" />
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>
      <div className="container martb ">
        <div className="row mb-5 justify-content-center">
          <div className="col-md-12 text-center">
            <div className="block-heading-1 text-white">
              <img
                className="theme_bg img-fluid"
                src={require('../../assert/images/img-gradient.png')}
                alt="img"
                title="GENESIS"
              />
              <img
                src={require('../../assert/images/img-GENESIS.png')}
                className="img-fluid z_property"
                alt="img"
                title="GENESIS"
              />
              <ScrollAnimation animateIn="fadeIn">
                <p className="txt-bold-36 mb-5 z_property"> GENESIS </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <div className="container">
                  <div className="row">
                    {gen.map((e, i) => {
                      return (
                        <>
                          <div
                            key={e.id}
                            className="col-xs-12 col-sm-6 col-md-3 my-4 card_img_main"
                          >
                            <img
                              src={e.imgs}
                              className="img-fluid border_radius_10 card_img"
                              alt="img"
                              title="G. ARA"
                            />
                            <div className="txt_maintitle">{e.title}</div>
                            <div className="btn_outline card_btn_img">
                              {e.speed}
                            </div>
                            <div>
                              [{e.level}] {e.time}
                              <img
                                src={require('../../assert/images/icn_shoess.png')}
                                alt="img"
                                className="ml-3 img-fluid"
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      <div className="container martb">
        <div className="row mb-5 justify-content-center">
          <div className="col-md-12 text-center">
            <div className="block-heading-1 text-white">
              <img
                className="theme_bg img-fluid"
                src={require('../../assert/images/img-gradient.png')}
                alt="img"
                title="GENESIS"
              />
              <img
                src={require('../../assert/images/img-GENESIS.png')}
                className="img-fluid z_property"
                alt="img"
                title="GENESIS"
              />
              <ScrollAnimation animateIn="fadeIn">
                {' '}
                <p className="txt-bold-36 mb-5 z_property"> RARE </p>{' '}
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceInLeft"
                animateOut="bounceOutRight"
              >
                <div className="container">
                  <div className="row">
                    {rare.map((e, i) => {
                      return (
                        <>
                          <div
                            key={e.id}
                            className="col-xs-12 col-sm-6 col-md-3 my-4 card_img_main"
                          >
                            <img
                              src={e.imgs}
                              className="img-fluid border_radius_10 card_img"
                              alt="img"
                              title="G. ARA"
                            />
                            <div className="txt_maintitle">{e.title}</div>
                            <div className="btn_outline">{e.speed}</div>
                            <div>
                              [{e.level}] {e.time}
                              <img
                                src={require('../../assert/images/icn_shoess.png')}
                                alt="img"
                                className="ml-3 img-fluid"
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      <div className="container martb">
        <div className="row mb-5 justify-content-center">
          <div className="col-md-12 text-center">
            <div className="block-heading-1 text-white">
              <img
                className="theme_bg img-fluid"
                src={require('../../assert/images/img-gradient.png')}
                alt="img"
                title="GENESIS"
              />
              <img
                src={require('../../assert/images/img-GENESIS.png')}
                className="img-fluid z_property"
                alt="img"
                title="GENESIS"
              />
              <ScrollAnimation animateIn="fadeIn">
                {' '}
                <p className="txt-bold-36 mb-5 z_property"> EPIC </p>{' '}
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceInRight"
                animateOut="bounceOutLeft"
              >
                <div className="container">
                  <div className="row">
                    {epic.map((e, i) => {
                      return (
                        <>
                          <div
                            key={e.id}
                            className="col-xs-12 col-sm-6 col-md-3 my-4 card_img_main"
                          >
                            <img
                              src={e.imgs}
                              className="img-fluid border_radius_10 card_img"
                              alt="img"
                              title="G. ARA"
                            />
                            <div className="txt_maintitle">{e.title}</div>
                            <div className="btn_outline">{e.speed}</div>
                            <div>
                              [{e.level}] {e.time}
                              <img
                                src={require('../../assert/images/icn_shoess.png')}
                                alt="img"
                                className="ml-3 img-fluid"
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
      <div className="container martb">
        <div className="row mb-5 justify-content-center">
          <div className="col-md-12 text-center">
            <div className="block-heading-1 text-white">
              <img
                className="theme_bg img-fluid"
                src={require('../../assert/images/img-gradient.png')}
                alt="img"
                title="GENESIS"
              />

              <img
                src={require('../../assert/images/img-GENESIS.png')}
                className="img-fluid z_property"
                alt="img"
                title="GENESIS"
              />
              <ScrollAnimation animateIn="fadeIn">
                <p className="txt-bold-36 mb-5 z_property"> LEGENDARY </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="bounceInLeft"
                animateOut="bounceOutRight"
              >
                <div className="container">
                  <div className="row">
                    {la.map((e, i) => {
                      return (
                        <>
                          <div
                            key={e.id}
                            className="col-xs-12 col-sm-6 col-md-3 my-4 card_img_main"
                          >
                            <img
                              src={e.imgs}
                              className="img-fluid border_radius_10 card_img"
                              alt="img"
                              title="G. ARA"
                            />
                            <div className="txt_maintitle">{e.title}</div>
                            <div className="btn_outline">{e.speed}</div>
                            <div>
                              [{e.level}] {e.time}
                              <img
                                src={require('../../assert/images/icn_shoess.png')}
                                alt="img"
                                className="ml-3 img-fluid"
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MagicClub;

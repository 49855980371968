import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation, NavLink } from 'react-router-dom';

const NavbarL = () => {
  const [back, setback] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [location]);

  const bb = () => {
    if (window.scrollY >= 50) {
      setback(true);
    } else {
      setback(false);
    }
  };
  window.addEventListener('scroll', bb);

  return (
    <div className={back ? 'fixed-top site-navbar' : 'site-navbar  '}>
      <Container fluid>
        <Navbar collapseOnSelect expand="xl">
          <Navbar.Brand href="#mshome">
            <img
              className="Logo"
              src={require('../../assert/images/logo_magicshoes.png')}
              alt=""
              style={{ maxWidth: '75%' }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav className=" align-items-center">
              <Nav.Link
                href="https://www.magicclub.app/shoes?type=all"
                eventKey={1}
              >
                MagicShoes NFT
              </Nav.Link>
              <Nav.Link href="https://magicclub.app/">MagicClub </Nav.Link>
              <Nav.Link href="#magicclubmarketplace">Shoes App</Nav.Link>
              <Nav.Link href="https://adam-gallery-2.gitbook.io/magic-club/">
                WhitePaper
              </Nav.Link>
              <Nav.Link href="#faq">FAQ</Nav.Link>
              {/* <Nav.Link eventKey={7} href="#" className='email_icon'>
                                <img src={require('../../assert/images/icon_email.png')} alt="img" title="Email" />
                            </Nav.Link>
                            <Nav.Link eventKey={8} href="#" className='fox_icon'>
                                <img src={require('../../assert/images/icon_MetaMask.png')} alt="img" title="Meta Mask" style={{ maxWidth: '80%' }} />
                            </Nav.Link> */}
              {/* <Nav.Link href="#" className='drop_menu'>

                                <DropdownButton
                                    menualign="left"
                                    title="ENG"
                                    id="dropdown-menu-align-left"
                                >
                                    <Dropdown.Item eventKey="Languages1">Languages1</Dropdown.Item>
                                    <Dropdown.Item eventKey="Languages1">Languages2</Dropdown.Item>
                                    <Dropdown.Item eventKey="Languages1">Languages3</Dropdown.Item>
                                </DropdownButton>
                            </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};

export default NavbarL;

import React from 'react';
import { Link } from 'react-router-dom';
import sample from '../../assert/images/herovideo.mp4';

const BanneL = () => {
  return (
    <div className="" id="mshome">
      <div className="ftco-blocks-cover-1">
        <div className="ftco-cover-2 overlay text-white bg_ftco ">
          <div class="onb-embed-responsive onb-embed-responsive__16by9">
            <video
              className="videoTag onb-embed-responsive-item"
              autoPlay
              loop
              muted
              id="myVideo"
            >
              <source src={sample} type="video/mp4" />
            </video>
            <div className="container text-center video_mob">
              {/* <div className="banner_logo">
                <img
                  src={require('../../assert/images/img-big-magicshoes.png')}
                  className="img-fluid"
                  alt="img"
                  title="MagicShoes"
                  style={{ maxWidth: '75%' }}
                />
              </div> */}
              <div className="row align-items-center justify-content-center text-center">
                <div className="">
                  <div className="banner_txt">
                    <p className="mb-2">
                      MagicShoes is a WEB3 lifestyle app that rewards users for
                      moving. Users with MagicShoes NFTs can benefit from
                      walking, running and cycling outdoors. You can exercise
                      with four types of shoe NFTs in the app.
                    </p>
                    <h1 className="txt-bold-64 txt_mob my-3">DOWNLOAD NOW</h1>
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-4 marb30 aling_imgs">
                          <Link
                            to="https://testflight.apple.com/join/588ursID"
                            target="_blank"
                          >
                            <img
                              src={require('../../assert/images/apple_store.png')}
                              className="img-fluid border_radius_10 "
                              alt="img"
                              title="App Store"
                              style={{ maxWidth: '100%' }}
                            />
                          </Link>
                        </div>
                        <div className="col-md-4 aling_imgs">
                          <Link
                            to="https://play.google.com/store/apps/details?id=com.magic.shoes.dev"
                            target="_blank"
                          >
                            <img
                              src={require('../../assert/images/google_play_store.png')}
                              className="img-fluid border_radius_10 "
                              alt="img"
                              title="Google Store"
                              style={{ maxWidth: '100%' }}
                            />
                          </Link>
                        </div>
                        <div className="col-md-4 aling_imgs">
                          <Link
                            to="https://firebasestorage.googleapis.com/v0/b/magicshoes-18612.appspot.com/o/magicshoes.apk?alt=media&token=a3efaf7e-0a60-4041-a08f-43962dbb2d31"
                            target="_blank"
                          >
                            <img
                              src={require('../../assert/images/android.png')}
                              className="img-fluid border_radius_10 "
                              alt="img"
                              title="Android APK"
                              style={{ maxWidth: '100%' }}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container text-center video_desk">
            {/* <div className="banner_logo">
              <img
                src={require('../../assert/images/img-big-magicshoes.png')}
                className="img-fluid"
                alt="img"
                title="MagicShoes"
                style={{ maxWidth: '75%' }}
              />
            </div> */}
            <div className="row align-items-center justify-content-center text-center">
              <div className="">
                <div className="banner_txt">
                  <p className="mb-2">
                    MagicShoes is a WEB3 lifestyle app that rewards users for
                    moving. Users with MagicShoes NFTs can benefit from walking,
                    running and cycling outdoors. You can exercise with four
                    types of shoe NFTs in the app.
                  </p>
                  <h1 className="txt-bold-64 txt_mob my-3">DOWNLOAD NOW</h1>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6 marb30">
                        <Link
                          to="https://testflight.apple.com/join/588ursID"
                          target="_blank"
                        >
                          <img
                            src={require('../../assert/images/img-app-store.jpg')}
                            className="img-fluid border_radius_10 btn_img"
                            alt="img"
                            title="App Store"
                            style={{ maxWidth: '75%' }}
                          />
                        </Link>
                      </div>
                      <div className="col-md-6">
                        <Link
                          to="https://play.google.com/store/apps/details?id=com.magic.shoes.dev"
                          target="_blank"
                        >
                          <img
                            src={require('../../assert/images/img-google-play.jpg')}
                            className="img-fluid border_radius_10 btn_img"
                            alt="img"
                            title="App Store"
                            style={{ maxWidth: '75%' }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BanneL;

import React, { useState } from 'react'
import { FaArrowUp } from "react-icons/fa";


const Scrollbtn = () => {
    const [visible, setVisible] = useState(false)
    
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
          setVisible(true)
        } 
        else if (scrolled <= 300){
          setVisible(false)
        }
      };

    window.addEventListener('scroll', toggleVisible);
    return (
        <div>
            <button onClick={() => window.scroll(0, 0)}
                style={{ display: visible ? 'inline' : 'none' }}
                className="scrollbtn">
                <FaArrowUp />
            </button>
        </div>
    )
}

export default Scrollbtn
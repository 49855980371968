import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';


const MobileApp = () => {

    return (
        <>
            <div className="container martb" id='magicclubmarketplace'>
                <ScrollAnimation animateIn='flipInY'
                    animateOut='flipOutY'>
                    <div className="row mb-5 justify-content-center">
                        <div className="col-md-12 text-center">
                            <div className="block-heading-1 text-white">
                                <p className="txt-bold-64 txt_mob">MagicShoes APP</p>
                                <p >Magic Shoes tracks your workout data, records distance, time. Compare your personal records or compete with other users. Recommended for anyone pursuing a healthy lifestyle, this app adds fun and enjoyment to exercise and helps users stay focused through gamification.</p>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
            <div className="container martb">
                <div className="row mb-5 justify-content-center">
                    <div className="col-md-12 text-center" >
                        <div className="block-heading-1 text-white">
                            <p className="txt-bold-36 mb-5"> Download The App </p>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-xs-12 col-sm-6 col-md-4 marb30 aling_img">
                                        <a href="https://testflight.apple.com/join/588ursID" target="_blank">
                                            <ScrollAnimation animateIn='bounceInRight'
                                                animateOut='bounceOutLeft'>
                                                <img src={require("../../assert/images/img-app-store.jpg")} className="img-fluid border_radius_10 mob_logo btn_img" alt="img" title="App Store" style={{ maxWidth: '60%' }} />
                                            </ScrollAnimation>
                                        </a>
                                    </div>
                                    <div className="col-xs-12 col-sm-6 col-md-4 aling_imgs">
                                        <a href="https://play.google.com/store/apps/details?id=com.magic.shoes.dev" target="_blank">
                                            <ScrollAnimation animateIn='bounceInLeft'
                                                animateOut='bounceOutRight'>
                                                <img src={require("../../assert/images/img-google-play.jpg")} className="img-fluid border_radius_10 mob_logo btn_img" alt="img" title="App Store" style={{ maxWidth: '60%' }} />
                                            </ScrollAnimation>

                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container martb">
                <div className="row mb-5 justify-content-center">
                    <div className="col-md-12 text-center">
                        <ScrollAnimation animateIn='wobble'
                            initiallyVisible={true}>
                            <img src={require("../../assert/images/img-magicshoesapp-info.png")} className="img-fluid border_radius_10"
                                alt="img" title="MagicShoes" />
                        </ScrollAnimation>

                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileApp
import React, { useRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
const FAQ = () => {
  const contentEl = useRef();

  const [active, setActive] = useState(null);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  const faqs = [
    {
      id: 1,
      header: 'Q: What are Magic Shoes?',
      text: `A: Magic Shoes is a  mobile application that enables users to earn MCT by walking or running outdoors after purchasing Magic Shoes NFTs. It is a polygon blockchain reward-based Move to Earn platform.`,
    },
    {
      id: 2,
      header: 'Q: What is the minting date? ',
      text: `A: The minting date for Magic Shoes is scheduled for June 2023. Detailed information regarding the minting date, price, and quantity will be announced through the official Discord channel.`,
    },
    {
      id: 3,
      header: 'Q: I am curious about the Magic Shoes app.',
      text: `A: The Magic Shoes app is a GPS-based application that allows users to effortlessly earn MCT tokens in their daily lives through activities like walking or running.`,
    },
    {
      id: 4,
      header: 'Q: Where can I buy shoes and how much do they cost?',
      text: `A: Magic Shoes can be purchased within the app's in-app market and MagicClub Marketplace. The price of the shoes is not fixed and is determined by the principles of supply and demand.`,
    },
    {
      id: 5,
      header: 'Q: How are Magic Shoes used?',
      text: `A: Magic Shoes NFTs are the NFT shoes utilized within the Magic Shoes app. The number of Magic Shoes owned and the cumulative rarity bonus determine the user's exercise time and ability.`,
    },
    {
      id: 6,
      header: 'Q: What types of Magic Shoes are available?',
      text: `A: There are several types of Magic Shoes, including Maru, Raon, Dawon, and Ara. Each type has its own specific features and descriptions.`,
    },
    {
      id: 7,
      header: 'Q: What stats do Magic Shoes have? ',
      text: `A: Magic Shoes have several stats. Higher level leads to better MCP rewards. Mining a larger amount of MCP will increase your weekly reward rate, allowing you to receive a larger amount of MCT tokens.The higher the class and rarity of the shoe, the higher the magic box is created and the more MCP can be obtained.The rarity of shoes is Genesis, Rare, Epic, and Legendary, which is a total of 4 rarity. The higher the class and rarity of the shoe, the higher the magic box is created and the more MCP you can get.`,
    },
    {
      id: 8,
      header: 'Q: What is MCT? ',
      text: `A: MCT is a reward token given to users as a governance token for Magic Shoes and Magic Club, and will be used for various purposes such as leveling up shoes, repairing, and purchasing potions.`,
    },
    {
      id: 9,
      header: 'Q: What is MCP? ',
      text: `A: MCP is a point within the platform for calculating weekly rewards. If you exercise while wearing shoes, it can be mined, and rewards can be paid in proportion to the amount of MCP mined for a week. MCP is also used for the cost of opening Magic Boxes.`,
    },
  ];
  return (
    <>
      <div className="container" id="faq">
        <div className="row mb-5 justify-content-center">
          <div className="col-md-12 text-center">
            <div className="block-heading-1 text-white">
              <p className="txt-bold-64 txt_mob"> FAQ </p>
              <div className="card">
                <div className="card-body text-left">
                  {faqs.map((faq, index) => {
                    return (
                      <div key={index} className="rc-accordion-card">
                        <div className="rc-accordion-header">
                          <div
                            className={`rc-accordion-toggle p-3 ${
                              active === faq.id ? 'active' : ''
                            }`}
                            onClick={() => handleToggle(faq.id)}
                          >
                            <h5 className="rc-accordion-title">{faq.header}</h5>
                            <FaChevronDown className=" rc-accordion-icon"></FaChevronDown>
                          </div>
                        </div>
                        <div
                          ref={contentEl}
                          className={`rc-collapse ${
                            active === faq.id ? 'show' : ''
                          }`}
                          style={
                            active === faq.id
                              ? { height: 'unset' }
                              : { height: '0px' }
                          }
                        >
                          <div className="rc-accordion-body">
                            <p className="mb-0">{faq.text}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FAQ;

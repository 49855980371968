import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

const WhitePaper = () => {
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });
  return (
    <>
      <div className="container martb">
        <div className="row mb-lg-5 justify-content-center">
          <div className="col-md-12 text-center">
            <div className="block-heading-1 text-white">
              <p className="txt-bold-64 txt_mob"> The Token </p>
              <div className="container martb">
                <div className="row justify-content-center">
                  <div className="col-xs-12 col-sm-6 col-md-4">
                    <ScrollAnimation
                      animateIn="bounceInRight"
                      animateOut="bounceOutLeft"
                    >
                      <img
                        src={require('../../assert/images/img-MCP.png')}
                        className="img-fluid border_radius_10 token_img"
                        alt="img"
                        title="MCP"
                      />
                    </ScrollAnimation>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-4">
                    <ScrollAnimation
                      animateIn="bounceInLeft"
                      animateOut="bounceOutRight"
                    >
                      <img
                        src={require('../../assert/images/img-MCT.png')}
                        className="img-fluid border_radius_10 token_img"
                        alt="img"
                        title="MCT"
                      />
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
              <div className="container martb">
                <div className="row justify-content-center">
                  <div className="col-xs-12 col-sm-6 col-md-4 marb30">
                    <ScrollAnimation
                      animateIn="bounceInRight"
                      animateOut="bounceOutLeft"
                    >
                      {' '}
                      <img
                        src={require('../../assert/images/img-info-MCP.png')}
                        className="img-fluid border_radius_10 token_img"
                        alt="img"
                        title="MCP"
                      />
                    </ScrollAnimation>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-4 imgbottom d-flex align-items-center">
                    <ScrollAnimation
                      animateIn="bounceInLeft"
                      animateOut="bounceOutRight"
                    >
                      <img
                        src={require('../../assert/images/img-info-MCT.png')}
                        className="img-fluid border_radius_10 token_img"
                        alt="img"
                        title="MCT"
                      />
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container martb">
        <div className="row mb-5 justify-content-center">
          <div className="col-md-12 text-center">
            <div className="block-heading-1 text-white">
              <p className="txt-bold-64 txt_mob"> The Item </p>
              <div className="container martb">
                <div className="row justify-content-center">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <ScrollAnimation animateIn="fadeIn" delay={5}>
                      <img
                        src={require('../../assert/images/image-magicshoes-token2.png')}
                        className="img-fluid border_radius_10 token_img"
                        alt="img"
                        title="The Item"
                      />
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid martb">
        <div className="row mb-5 justify-content-center">
          <div className="col-md-12 text-center">
            <div className="block-heading-1 text-white">
              <p className="txt-bold-64 txt_mob"> ROADMAP </p>
              <div className="container-fluid martb">
                <div className="row justify-content-center">
                  <div className="">
                    {!isMobile ? (
                      <ScrollAnimation
                        animateIn="wobble"
                        initiallyVisible={true}
                      >
                        <img
                          src={require('../../assert/images/magicshoes_roadmap.png')}
                          className="img-fluid border_radius_10 token_img"
                          alt="img"
                          title="MagicShoes RoadMap"
                        />
                      </ScrollAnimation>
                    ) : (
                      <ScrollAnimation
                        animateIn="wobble"
                        initiallyVisible={true}
                      >
                        <img
                          src={require('../../assert/images/magicshoe_roadmap_mobile.png')}
                          className="img-fluid border_radius_10"
                          alt="img"
                          title="MagicShoes RoadMap"
                        />
                      </ScrollAnimation>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container martb">
        <div className="row mb-5 justify-content-center">
          <div className="col-md-12">
            <div className="block-heading-1 text-white text-center">
              <p className="txt-bold-36 mb-5"> TEAM </p>
              <ScrollAnimation animateIn="fadeIn" delay={8}>
                <div className="container">
                  <div className="row justify-content-center text-center">
                    <div className="col-xs-12 col-sm-6 col-md-2">
                      {' '}
                      <img
                        src={require('../../assert/images/Grace-Kang.png')}
                        className="img-fluid border_radius_10 team_img"
                        alt="img"
                        title="G. ARA"
                      />
                      <div className="txt_maintitle">GRACE</div>
                      <div>CEO </div>
                      {/* <Link
                        to="https://www.linkedin.com/in/magicclub-io-01182a24a"
                        target="_blank"
                      >
                        <img
                          src={require('../../assert/images/img-linkedin.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1 mr-2"
                          width={20}
                          title="Linkedin"
                        />
                      </Link>
                      <Link
                        to="https://twitter.com/magic_club_io/"
                        target="_blank"
                      >
                        <img
                          src={require('../../assert/images/img-twitter.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1"
                          width={20}
                          title="Twitter"
                        />
                      </Link> */}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                      {' '}
                      <img
                        src={require('../../assert/images/Jeong.png')}
                        className="img-fluid border_radius_10 team_img"
                        alt="img"
                        title="G. ARA"
                      />
                      <div className="txt_maintitle">JOSEPH</div>
                      <div>CTO</div>
                      {/* <Link
                        to="https://www.linkedin.com/in/magicclub-io-01182a24a"
                        target="_blank"
                      >
                        <img
                          src={require('../../assert/images/img-linkedin.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1 mr-2"
                          width={20}
                          title="Linkedin"
                        />
                      </Link>
                      <Link
                        to="https://twitter.com/magic_club_io/"
                        target="_blank"
                      >
                        <img
                          src={require('../../assert/images/img-twitter.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1"
                          width={20}
                          title="Twitter"
                        />
                      </Link> */}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                      {' '}
                      <img
                        src={require('../../assert/images/William.png')}
                        className="img-fluid border_radius_10 team_img"
                        alt="img"
                        title="G. ARA"
                      />
                      <div className="txt_maintitle">WILLIAM</div>
                      <div>Full stack developer </div>
                      {/* <Link
                        to="https://www.linkedin.com/in/magicclub-io-01182a24a"
                        target="_blank"
                      >
                        <img
                          src={require('../../assert/images/img-linkedin.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1 mr-2"
                          width={20}
                          title="Linkedin"
                        />
                      </Link>
                      <Link
                        to="https://twitter.com/magic_club_io/"
                        target="_blank"
                      >
                        <img
                          src={require('../../assert/images/img-twitter.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1"
                          width={20}
                          title="Twitter"
                        />
                      </Link> */}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                      {' '}
                      <img
                        src={require('../../assert/images/HanSung2.png')}
                        className="img-fluid border_radius_10 team_img"
                        alt="img"
                        title="G. ARA"
                      />
                      <div className="txt_maintitle">JAMES</div>
                      <div>Backend Engineer</div>
                      {/* <Link
                        to="https://www.linkedin.com/in/magicclub-io-01182a24a"
                        target="_blank"
                      >
                        <img
                          src={require('../../assert/images/img-linkedin.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1 mr-2"
                          width={20}
                          title="Linkedin"
                        />
                      </Link>
                      <Link
                        to="https://twitter.com/magic_club_io/"
                        target="_blank"
                      >
                        <img
                          src={require('../../assert/images/img-twitter.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1"
                          width={20}
                          title="Twitter"
                        />
                      </Link> */}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                      {' '}
                      <img
                        src={require('../../assert/images/Choo2.png')}
                        className="img-fluid border_radius_10 team_img"
                        alt="img"
                        title="G. ARA"
                      />
                      <div className="txt_maintitle">CHOO</div>
                      <div>Frontend Engineer</div>
                      {/* <Link
                        to="https://www.linkedin.com/in/magicclub-io-01182a24a"
                        target="_blank"
                      >
                        <img
                          src={require('../../assert/images/img-linkedin.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1 mr-2"
                          width={20}
                          title="Linkedin"
                        />
                      </Link>
                      <Link
                        to="https://twitter.com/magic_club_io/"
                        target="_blank"
                      >
                        <img
                          src={require('../../assert/images/img-twitter.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1"
                          width={20}
                          title="Twitter"
                        />
                      </Link> */}
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-md-12 text-center md: mt-4">
            <div className="block-heading-1 text-white">
              <div className="container">
                <ScrollAnimation animateIn="fadeIn" delay={12}>
                  <div className="row justify-content-center text-center">
                    <div className="col-xs-12 col-sm-6 col-md-2 ">
                      {' '}
                      <img
                        src={require('../../assert/images/Moon2.png')}
                        className="img-fluid border_radius_10 team_img"
                        alt="img"
                        title="G. ARA"
                      />
                      <div className="txt_maintitle">SPENCER</div>
                      <div>Designer </div>
                      {/* <Link to="https://www.google.com/" target="_blank">
                        <img
                          src={require('../../assert/images/img-linkedin.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1 mr-2"
                          width={20}
                          title="Linkedin"
                        />
                      </Link>
                      <Link to="https://www.google.com/" target="_blank">
                        <img
                          src={require('../../assert/images/img-twitter.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1"
                          width={20}
                          title="Twitter"
                        />
                      </Link> */}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                      {' '}
                      <img
                        src={require('../../assert/images/Lee-hyeheon2.png')}
                        className="img-fluid border_radius_10 team_img"
                        alt="img"
                        title="G. ARA"
                      />
                      <div className="txt_maintitle">ANDREW</div>
                      <div>Artist</div>
                      {/* <Link to="https://www.google.com/" target="_blank">
                        <img
                          src={require('../../assert/images/img-linkedin.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1 mr-2"
                          width={20}
                          title="Linkedin"
                        />
                      </Link>
                      <Link to="https://www.google.com/" target="_blank">
                        <img
                          src={require('../../assert/images/img-twitter.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1"
                          width={20}
                          title="Twitter"
                        />
                      </Link> */}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                      {' '}
                      <img
                        src={require('../../assert/images/Jessy2.png')}
                        className="img-fluid border_radius_10 team_img"
                        alt="img"
                        title="G. ARA"
                      />
                      <div className="txt_maintitle">JESSY</div>
                      <div>Artist </div>
                      {/* <Link to="https://www.google.com/" target="_blank">
                        <img
                          src={require('../../assert/images/img-linkedin.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1 mr-2"
                          width={20}
                          title="Linkedin"
                        />
                      </Link>
                      <Link to="https://www.google.com/" target="_blank">
                        <img
                          src={require('../../assert/images/img-twitter.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1"
                          width={20}
                          title="Twitter"
                        />
                      </Link> */}
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-2">
                      {' '}
                      <img
                        src={require('../../assert/images/Park-SooYong2.png')}
                        className="img-fluid border_radius_10 team_img"
                        alt="img"
                        title="G. ARA"
                      />
                      <div className="txt_maintitle">MASON</div>
                      <div>Advisor </div>
                      {/* <Link to="https://www.google.com/" target="_blank">
                        <img
                          src={require('../../assert/images/img-linkedin.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1 mr-2"
                          width={20}
                          title="Linkedin"
                        />
                      </Link>
                      <Link to="https://www.google.com/" target="_blank">
                        <img
                          src={require('../../assert/images/img-twitter.png')}
                          alt="img"
                          className="img-fluid marb30 mt-1"
                          width={20}
                          title="Twitter"
                        />
                      </Link> */}
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container martb">
        <div className="row mb-5 justify-content-center">
          <div className="col-md-12 text-center">
            <div className="block-heading-1 text-white">
              <p className="txt-bold-36 mb-5"> PARTNER & COMMUNITIES </p>
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/bsc.png')}
                      className="img-fluid border_radius_10 mb-4 p-5 p-md-2 "
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/puffverse.png')}
                      className="img-fluid border_radius_10 mb-0 p-5 pt-md-4"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/souffl3.png')}
                      className="img-fluid border_radius_10 mb-4 p-md-2 pt-md-2 p-4"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/solak.png')}
                      className="img-fluid border_radius_10 mb-4 pt-5 pl-5 pr-5 p-md-3 mt-1"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  <div className="w-100"></div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/mar3.png')}
                      className="img-fluid border_radius_10 mb-4 p-5 p-md-3"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  {/* <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/srlabs.png')}
                      className="img-fluid border_radius_10 mb-4 pt-4 p-5 p-md-3"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div> */}
                  {/*addtional partners*/}
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/TwiPlay.png')}
                      className="img-fluid border_radius_10 mb-4 mt-1 p-2 p-md-0"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/Mises.png')}
                      className="img-fluid border_radius_10 mb-4 mt-md-3 mb-4 p-md-2 mt-5 p-3"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/aiverse1.png')}
                      className="img-fluid border_radius_10 ml-2 p-5 p-md-2 mt-3"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/horizonland.png')}
                      className="img-fluid border_radius_10 p-5 mt-md-2 p-md-3"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  {/* <div className="w-100"></div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/Calls.png')}
                      className="img-fluid border_radius_10 mb-4 pt-4 p-5 p-md-4"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/Cryptopia.png')}
                      className="img-fluid border_radius_10 mb-4 pt-5 p-5 p-md-4"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/ZeroManor2.png')}
                      className="img-fluid border_radius_10 mb-4 pt-1 p-3 p-md-4"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>{' '} */}

                  {/* <div className="w-100"></div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/img-outline-rectangle.png')}
                      className="img-fluid border_radius_10 mb-4"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/img-outline-rectangle.png')}
                      className="img-fluid border_radius_10 mb-4"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/img-outline-rectangle.png')}
                      className="img-fluid border_radius_10 mb-4"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/img-outline-rectangle.png')}
                      className="img-fluid border_radius_10 mb-4"
                      alt="img"
                      title="PARTNER & COMMUNITIES"
                    />{' '}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container martb">
        <div className="row mb-5 justify-content-center">
          <div className="col-md-12 text-center">
            <div className="block-heading-1 text-white">
              <p className="txt-bold-36 mb-5"> AUDITED & VERIFIED </p>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/certik.png')}
                      className="img-fluid border_radius_10 mb-4 pt-5 pl-5 pr-5 p-md-3"
                      alt="img"
                      title="AUDITED & VERIFIED"
                    />{' '}
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    {' '}
                    <img
                      src={require('../../assert/images/solidproof.png')}
                      className="img-fluid border_radius_10 mb-4 pt-5 pl-5 pr-5 p-md-4"
                      alt="img"
                      title="AUDITED & VERIFIED"
                    />{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhitePaper;
